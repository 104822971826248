import React from 'react'
import { useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import './EventDetail.scss'

const eventList = [
  {
    id: 1,
    imageSrc: '/img/event/photo_event_1.png',
    title:
      '2 червня – 3-й проєктний семінар “Навчальний курс: управління інтернаціоналізацією та німецько-українська академічна співпраця 2022-2023”',
    date: '2 червня 2023',
    description:
      '2 червня 2023 року на базі Київського національного університету імені Тараса Шевченка відбудеться 3-й проєктний семінар “Навчальний курс: управління інтернаціоналізацією та німецько-українська академічна співпраця 2022-2023”',
  },
  {
    id: 2,
    imageSrc: '/img/event/photo_event_2.png',
    title:
      '12 квітня 2024 року відбудеться зустріч із представниками проєкту IHES',
    date: '12 квітня 2024',
    description:
      'Уже в найближчу п’ятницю відбудеться зустріч представників PNRM з представниками проєкту IHES. \n\nПочаток об 14:00 CET (за центральноєвропейським часом).\n\nЗустріч відбудеться в онлайн форматі на платформі Microsoft Teams. \n\nПриєднатися до зустрічі можна за реквізитами: \n\nID зустрічі: 247 425 832 060 \n\nПароль: XNyvhW \n\nІнтернаціоналізація у вищій освіті для суспільства (IHES) має на меті змінити це та активізувати потенціал інтернаціоналізації для соціальної взаємодії. Цей проєкт спрямований на побудову заходів, що стосуються реальних потреб суспільства. \n\nДокладніше тут https://ihes.upol.cz/',
  },
  {
    id: 3,
    imageSrc: '/img/event/photo_event_3.png',
    title:
      '14 травня 2024 року відбудеться зустріч "Being Visible as a Science Manager"',
    date: '14 травня 2024',
    description:
      '"Being Visible as a Science Manager" \n\nЯк менеджер науки, ви  більшість своєї діяльності проводите без будь-якої  видимості. Видимі ваші  результати. Але як на рахунок вашої власної видимості? З якою метою і перед ким ви хочете бути видимими? \n\nPNRM-вці спільно з мережею управління наукою в Німеччині (NWM) та Потсдамською аспірантською школою (PoGS) запрошують вас на інтерактивний цифровий мінісемінар: „Being Visible as a Science Manager“. \n\nКоли? Вівторок, 14 травня 2024 року \n\nЧас? 2:00 p.m. - 3:30 p.m. (CET) / 3:00 p.m. - 4:30 p.m. (OET) \n\nДе? Zoom. \n\nЩоб взяти участь обов’язкова реєстрація тут👉  Being Visible as a Science Manager Tickets, Di, 14.05.2024 um 14:00 Uhr | Eventbrite. \n\nКількість місць обмежена.',
  },
  {
    id: 5,
    imageSrc: '/img/event/photo_event_5.png',
    title:
      '26 ТА 27 ВЕРЕСНЯ 2024 РОКУ В СААРСЬКОМУ УНІВЕРСИТЕТІ В СААРБРЮКЕНІ ВІДБУДЕТЬСЯ МЕРЕЖЕВА КОНФЕРЕНЦІЯ «УКРАЇНСЬКІ ДНІ В СААРЛАНДІ»',
    date: '26-27 вересня 2024',
    description:
      '26 та 27 вересня 2024 року в Саарському університеті в Саарбрюкені відбудеться мережева конференція «Українські дні в Саарланді», яку організовує професор Андрій Лужецький у співпраці з Німецько-українським академічним товариством. \n\nМетою заходу є посилення наукового обміну з Україною. Захід фінансується DAAD у рамках програми Go-East за кошти BMBF. Участь можлива онлайн або на кампусі та є безкоштовною. \n\nРеєстрація обов’язкова на сайті \n\nwww.amegbio.com/ukrainian-days-in-saarland \n\nподії до 31.08.2024 \n\nДодаткова інформація \n\nhttps://www.amegbio.com/ukrainian-days-in-saarland \n\nпро подію та Німецько-українське академічне товариство.',
  },
  {
    id: 4,
    imageSrc: '/img/event/photo_event_4.png',
    title: 'Воркшоп BAYHOST: Співпраця з Україною у сфері гуманітарних наук',
    date: '24-25 жовтня 2024',
    description:
      'Воркшоп BAYHOST: Співпраця з Україною у сфері гуманітарних наук \n\nBAYHOST, Дослідницький центр німецької мови у Центральній, Східній та Південно-Східній Європі (FZ DiMOS), від кафедри “Слов’янська філологія – лінгвістика” Інституту славістики Регенсбурзького університету, та Університет ім. Андраші в Будапешті (AUB) організовують воркшоп з питань співпраці з Україною в сфері гуманітарних наук, з 24 по 25 жовтня у Будапешті у гібридному форматі.\n\nУчасть у конференції безкоштовна, можлива фінансова допомога на проживання та проїзд.\n\nЗаявку на участь подати потрібно до 30 червня: \n\nhttps://www.daad-ukraine.org/uk/2024/06/06/vorkshop-bayhost-spivpratsya-z-ukrayinoyu-v-sferi-gumanitarnih-nauk/',
  },
  // {
  //   id: 2,
  //   imageSrc:
  //   title:
  //   date:
  //   description:
  // },
]

// без розриву тексту!
// function EventDetail() {
//   const { eventIndex } = useParams()
//   const selectedEvent = eventList.find(
//     (event) => event.id === parseInt(eventIndex)
//   )

//   if (!selectedEvent) {
//     return <div className="detail__error">Error, page not found.</div>
//   }

//   return (
//     <div className="container">
//       <div className="detail">
//         <div className="detail__image">
//           <img src={selectedEvent.imageSrc} alt="#" />
//         </div>
//         <div className="detail__description">
//           <div className="detail__text-1">
//             <p>
//               <FormattedMessage id={`eventDetail.title.${selectedEvent.id}`} />
//             </p>
//             <div className="detail__date">
//               <p>
//                 <FormattedMessage id={`eventDetail.date.${selectedEvent.id}`} />
//               </p>
//             </div>
//             <div className="detail__text-2">
//               <p>
//                 <FormattedMessage
//                   id={`eventDetail.description.${selectedEvent.id}`}
//                 />
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }
function EventDetail() {
  const { eventIndex } = useParams()
  const selectedEvent = eventList.find(
    (event) => event.id === parseInt(eventIndex)
  )

  if (!selectedEvent) {
    return <div className="detail__error">Error, page not found.</div>
  }

  const description = selectedEvent.description
  const paragraphs = description.split('\n\n')

  return (
    <div className="container">
      <div className="detail">
        <div className="detail__image">
          <img src={selectedEvent.imageSrc} alt="#" />
        </div>
        <div className="detail__description">
          <div className="detail__text-1">
            <p>
              <FormattedMessage id={`eventDetail.title.${selectedEvent.id}`} />
            </p>
            <div className="detail__date">
              <p>
                <FormattedMessage id={`eventDetail.date.${selectedEvent.id}`} />
              </p>
            </div>
          </div>
          <div className="detail__text-2">
            {paragraphs.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventDetail
