import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import YouTubeChannel from '../../components/channel/YouTubeChannel'
import './Eventing.scss'

const events = [
  {
    id: 1,
    imageSrc: './img/event/photo_event_1.png',
    title:
      '3-й проєктний семінар «Навчальний курс: управління інтернаціоналізацією та німецько-українська академічна співпраця 2022-2023»',
    date: '02.06.2023',
    views: 2890,
  },
  {
    id: 2,
    imageSrc: './img/event/photo_event_2.png',
    title:
      '12 квітня 2024 року відбудеться зустріч із представниками проєкту IHES',
    date: '12.04.2024',
    views: 512,
  },
  {
    id: 3,
    imageSrc: './img/event/photo_event_3.png',
    title:
      '14 травня 2024 року відбудеться зустріч "Being Visible as a Science Manager"',
    date: '14.05.2024',
    views: 53,
  },
  {
    id: 5,
    imageSrc: './img/event/photo_event_5.png',
    title:
      '26 ТА 27 ВЕРЕСНЯ 2024 РОКУ В СААРСЬКОМУ УНІВЕРСИТЕТІ В СААРБРЮКЕНІ ВІДБУДЕТЬСЯ МЕРЕЖЕВА КОНФЕРЕНЦІЯ «УКРАЇНСЬКІ ДНІ В СААРЛАНДІ»',
    date: '26-27.09.2024',
    views: 41,
  },
  {
    id: 4,
    imageSrc: './img/event/photo_event_4.png',
    title: 'Воркшоп BAYHOST: Співпраця з Україною у сфері гуманітарних наук',
    date: '24-25.10.2024',
    views: 61,
  },
]

const additionalEvents = [
  // {
  //   id: 2,
  //   imageSrc:
  //   title:
  //   date:
  //   views:
  // },
]

function Eventing() {
  const [showAllEvent, setShowAllEvent] = useState(false)
  const eventsToShow = showAllEvent ? [...events, ...additionalEvents] : events

  const readMore = () => {
    setShowAllEvent(true)
  }

  return (
    <div className="container">
      <div className="event">
        <div className="event__title">
          <h2 className="event__title-text">
            <FormattedMessage id="event.title" />
          </h2>
        </div>
        <div className="event__channel">
          <YouTubeChannel />
        </div>
        {eventsToShow.map((event) => (
          <Link
            to={`/event/${event.id}`}
            className="event__block-1"
            key={event.id}
          >
            <img src={event.imageSrc} alt="#" className="event__image" />
            <div className="event__description">
              <div className="event__text">
                <p className="event__description-text">
                  <FormattedMessage id={`event.title.${event.id}`} />
                </p>
                <div className="event__additional">
                  <p className="event__date">{event.date}</p>
                  <div className="event__views">
                    <img
                      src="./img/event/eye_icon.png"
                      alt="#"
                      className="event__icon"
                    />
                    <p className="event__views-count">{event.views}</p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}
        {!showAllEvent && (
          <button className="event__button" onClick={readMore}>
            <FormattedMessage id="event.readMore" />
          </button>
        )}
        <div className="event__block-2">
          <h3 className="event__heading">
            <FormattedMessage id="event.linksHeading" />
          </h3>
          <p className="event__link">
            <FormattedMessage
              id="event.platformLink"
              values={{
                link: (
                  <a
                    href="https://ugf.academy/about-us/"
                    className="event__external-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://ugf.academy/about-us/
                  </a>
                ),
              }}
            />
          </p>
        </div>
      </div>
    </div>
  )
}

export default Eventing
