import React from 'react'
import { FormattedMessage } from 'react-intl'
import './Team.scss'

function Team() {
  const TeamMember = ({ photo, name, position, description }) => (
    <div className="container">
      <div className="photo">
        <img src={photo} alt="Team Member" />
        <div className="description">{description}</div>
      </div>
      <div className="info">
        <div className="name">{name}</div>
        <div className="position">{position}</div>
      </div>
    </div>
  )

  const teamMembers = [
    {
      photo: './img/team/Tolstanova.png',
      name: 'Ганна Толстанова',
      position:
        'доктор біологічних наук, професор, експерт в галузі: організації науки, керівництво проєктами, докторські школи',
      description:
        "«Сьогодні кожен українець обороняє нашу країну на своєму фронті. Під час повномасштабного вторгнення росії на територію України ми стали ще більш згуртовані, відчули нашу цілісність й силу, а також підтримку всього демократичного світу. Для реалізації освітнього та наукового потенціалу без обмежень та кордонів активні освітяни та науковці об'єдналися та створили першу українсько-європейську спільноту Професійна мережа менеджерів освіти і науки України».",
    },
    {
      photo: './img/team/Petrunina.png',
      name: 'Ольга ПЕТРУНІНА',
      position: 'експерт з питання грантової підтримки, реалізації проектів',
      description:
        ' «У цей час для країни важливим є підтримка і розвиток кожної сфери, але працюючи над розвитком освіти, науки та інновацій ми маємо можливість планувати майбутнє нашої країни, готувати потужний кадровий потенціал». ',
    },
    {
      photo: './img/team/Bovtruk.png',
      name: 'Наталія БОВТРУК',
      position: 'комунікаційний експерт сфери освіти, науки та інновацій',
      description:
        ' «Для формування освітян та науковців важливо налагодити професійні комунікації, як стануть важливим підґрунтям для обміну досвідом, співпраці заради розвитку та становлення сфери освіти, науки та інновацій. Це своєю чергою стане неоціненною зброєю нашого фронту».',
    },
    {
      photo: './img/team/Gura.png',
      name: 'Вікторія ГУРА',
      position:
        'експерт з питань організації навчального процесу та репутаційного менеджменту',
      description:
        '«Без якісної освіти немає майбутнього. Ми живемо у важкі часи. Ніхто не вірив, що сусід може напасти на нас. Проте мужньо тримаємо освітній фронт. І навчаємо якісно та професійно. Війна - це страшно, але потрібно рухатися вперед та розвиватися, щоб відновити країну та повернутися до мирного життя. Спільними зусиллями ми відбудуємо та розбудуємо Україну, адже це наша рідна земля. Запрошую долучитися до нашої команди та рухатися вперед до Перемоги разом».',
    },
    {
      photo: './img/team/Krukevych.png',
      name: 'Оксана БЕРЕЖНА',
      position:
        'експерт з питань управління та фінансування науки, взаємодії науки та бізнесу, розвитку інновацій та трансферу технологій',
      description:
        ' «Україна щодня демонструє свою сміливість та героїчно протистоїть російській агресії, невпинно працює над подолання впливу загарбницьких дій рф, чим щодня доводить світу свою силу та міць. Безперечно Перемога за нами, але на жаль маємо багато руйнувань та втрат, які потребують якнайшвидшого відновлення.   і Сьогоднішні реалії також вимагають нових механізмів розвитку нашої країни, а саме  інноваційної, високотехнологічної та інвестиційно привабливих напрямів держави. Ми маємо неймовірний інтелектуальний, науковий та інноваційний потенціал, який потребує підтримки, злагоджених дій та взаємодії державних органів, наукової спільноти та бізнесу, І єдина Мережа в Україні відкриває такі можливості».',
    },
    {
      photo: './img/team/Lykhachova.png',
      name: 'Юлія ЛИХАЧОВА',
      position: 'експерт з питань розвитку інновацій',
      description:
        '«Сьогодення вимагає сучасних і швидких рішень. Саме тому створено мережу, яка обєднає в собі значний інтелектуальний потенціал для відбудови України інноваційним шляхом».',
    },
    {
      photo: './img/team/Pochaevets.png',
      name: 'Олена ПОЧАЄВЕЦЬ',
      position: ' експерт з питань грантової діяльності, управління проєктами',
      description:
        ' «Професійна діяльність майже кожного освітянина й науковця це проєктна діяльність. Особливість проєктів в тому, що вони мають свій початок і кінець, мають задачі, і ресурси для їх реалізації. Мати ідею та уміння ефективно реалізовувати проєкти, а особливо в умовах невизначеності, - наша особлива суперсила. Мережа дає можливість підвищити рівень суперсили, а отже всі ми - супергерої освітнього та наукового фронту».',
    },
    {
      photo: './img/team/Shalimova.png',
      name: 'Наталія ШАЛІМОВА',
      position: 'секретар мережі',
      description:
        '«Задля розвитку нашої країни, її наукового та освітнього потенціалу маємо  працювати систематично, а не ситуативно. Мережа створює можливості для спільного пошуку нових підходів, ідей, рішень, для обміну досвідом та кращими практиками на національному та міжнародному рівнях».',
    },
    {
      photo: './img/team/Konovalova.png',
      name: 'Катерина КОНОВАЛОВА',
      position: 'дизайнер',
      description:
        '«Сучасність, чіткість та інноваційність диктують тренди. Мережа менеджерів відкриває нові можливості для розвитку країни, транслює увагу до деталей, професіоналізм та виваженість, що знаходить своє відображення у візуальній частині мережі».',
    },
    {
      photo: './img/team/Gorobets.png',
      name: 'Дмитро ГОРОБЕЦЬ',
      position: 'front-end розробник',
      description:
        '«Час - це цінний ресурс, якого завжди бракує. Ця мережа є, свого роду, майданчиком для швидкого пошуку співпраці та взаємодії і комунікації між активними науковцями та освітянами».',
    },
  ]

  return (
    <div className="container">
      <div className="teams-page">
        <h2>
          <FormattedMessage id="founders" />
        </h2>
        <div className="team-members">
          {teamMembers.map((member, index) => (
            <TeamMember
              key={index}
              photo={member.photo}
              name={<FormattedMessage id={`team.member.name.${index + 1}`} />}
              position={
                <FormattedMessage id={`team.member.position.${index + 1}`} />
              }
              description={
                <FormattedMessage id={`team.member.description.${index + 1}`} />
              }
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Team
