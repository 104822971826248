import React, { useState } from 'react'
import './SliderStyles.scss'

const photos = [
  {
    url: './img/photos/photo_1.png',
    alt: 'Photo 1',
  },
  {
    url: './img/photos/photo_2.png',
    alt: 'Photo 2',
  },
  {
    url: './img/photos/photo_3.png',
    alt: 'Photo 3',
  },
  {
    url: './img/photos/photo_4.png',
    alt: 'Photo 4',
  },
  {
    url: './img/photos/photo_5.png',
    alt: 'Photo 5',
  },
  {
    url: './img/photos/photo_6.png',
    alt: 'Photo 6',
  },
  {
    url: './img/photos/photo_7.png',
    alt: 'Photo 7',
  },
  {
    url: './img/photos/photo_8.png',
    alt: 'Photo 8',
  },
  {
    url: './img/photos/photo_9.png',
    alt: 'Photo 9',
  },
  {
    url: './img/photos/photo_10.png',
    alt: 'Photo 10',
  },
]

function SliderGallery() {
  const [currentSlide, setCurrentSlide] = useState(0)

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % photos.length)
  }

  const prevSlide = () => {
    setCurrentSlide(
      (prevSlide) => (prevSlide - 1 + photos.length) % photos.length
    )
  }

  const openPhotoInNewTab = () => {
    window.open(photos[currentSlide].url, '_blank')
  }

  return (
    <div className="slider-container">
      <div className="slider-arrow arrow-left" onClick={prevSlide}>
        <img src="./img/network/arrow_left.png" alt="Left Arrow" />
      </div>
      <div className="slider-content" onClick={openPhotoInNewTab}>
        <img
          src={photos[currentSlide].url}
          alt={photos[currentSlide].alt}
          className="slider-photo"
        />
      </div>
      <div className="slider-arrow arrow-right" onClick={nextSlide}>
        <img src="./img/network/arrow_right.png" alt="Right Arrow" />
      </div>
    </div>
  )
}

export default SliderGallery
