import { FormattedMessage } from 'react-intl'
import './Blog.scss'

function Blog() {
  return (
    <div className="container">
      <div className="blog">
        <div className="blog__title">
          <p>
            <FormattedMessage id="blog.title" />
          </p>
        </div>
        <div className="blog__subtitle">
          <p>
            <FormattedMessage id="blog.subtitle" />
          </p>
        </div>
      </div>
    </div>
  )
}

export default Blog
